import React, { useContext, useState, useEffect, Fragment } from 'react';
import { useParams } from '@reach/router';
import ImageFadeIn from "react-image-fade-in";
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';
import { parse } from "query-string";
import { LogOut } from 'react-feather';

import { Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName, getUrl } from '@axeedge/go-shared-utils';

import ForgotPassword from './ForgotPassword';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGIN } from '../services/graphql';
import { APP_NAME } from '../../../services/constants';
import logo from '../../../images/boomer.svg';
import LoginForm from '../components/LoginForm';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../components/CountrySelect'

const RedirectAfterLogin = ({ location }) => {
    const { t } = useTranslation();

    const [token, setToken] = useState(ls(getAppTokenName(APP_NAME)));
    const params = useParams();
    const [formErrors, setFormErrors] = useState([]);
    const [modalForgotPass, setModalForgotPass] = useState(false);
    const client = useApolloClient();
    const { setCurrentUser } = useContext(AuthContext);
    const [showCountrySelect, setShowCountrySelect] = useState(false)

    const [login, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.teacherLogIn.errors && data.teacherLogIn.errors.length !== 0) {
                setFormErrors(data.teacherLogIn.errors);
                return;
            }
            if (data.teacherLogIn.teacher && data.teacherLogIn.teacher.id && data.teacherLogIn.token) {
                setToken(data.teacherLogIn.token);
                ls(getAppTokenName(APP_NAME), data.teacherLogIn.token);
                ls('go_teacher_user', JSON.stringify(data.teacherLogIn.teacher));
                data.teacherLogIn.teacher?.school?.id && ls('schoolId', data.teacherLogIn.teacher.school.id);

                setCurrentUser(data.teacherLogIn.teacher);
                client.writeData({
                    data: {
                        loggedIn: data.teacherLogIn.token,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    useEffect(() => {
        const schoolIdParam = ls('schoolId') ? `&schoolId=${ls('schoolId')}` : ''; 
        if (token) {
            if (parse(location.search).targetUrl) {
                window.location.href = `${getUrl(Number(params.app))}${parse(location.search).targetUrl}?token=${token}${schoolIdParam}`;
            } else {
                window.location.href = `${getUrl(Number(params.app))}?token=${token}${schoolIdParam}`;
            }
        }
    }, [token, params.app, location.search]);

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                {showCountrySelect ?
                <>
                    <CountrySelect setShowCountrySelect={setShowCountrySelect} />
                </>
                :
                <>                {
                    token ? (
                        <h1 className='u-text-center u-m-top-4'>We are logging you in...</h1>
                    ) : (
                        <Fragment>
                            <ImageFadeIn className='auth-page__logo' src={logo} />
                            <h1 className='auth-page__title'>{t('login')}</h1>
                            <div className='auth-page__logged-out'>
                                <LogOut className='auth-page__logged-out__icon' />
                                <p className='auth-page__logged-out__text'>{t('you_are_logged_out')}<br /> {t('log_back_in_to_continue')}</p>
                            </div>
                            <LoginForm formErrors={formErrors} loggingIn={loggingIn} loginMethod={login} setModalForgotPass={setModalForgotPass} setShowCountrySelect={setShowCountrySelect} />
                        </Fragment>
                    )
                }
                </>
                }

            </div>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default RedirectAfterLogin;
