import React, { useState, useContext, useEffect } from 'react';
import { ChevronsRight, Lock } from 'react-feather';
import axios from 'axios';
import { useApolloClient, useQuery, useMutation } from '@apollo/react-hooks';

import { GET_S3_UPLOAD_URL_QUERY } from '../../../../services/graphql';
import { GET_SCHOOL_QUERY, SAVE_GUIDE_URL, SET_PARENTS_GROUP_LOGS, } from './services/graphql';
import { GET_READ_SUBSCRIPTIONS, END_READ_ACCESS } from '../../services/graphql';
import { getAppTokenName, getUrl, APPS } from '@axeedge/go-shared-utils';
import boomreaderImg from '../../../../images/boomreader-logo.svg';
import ImageFadeIn from 'react-image-fade-in';
import ls from 'local-storage';
import { APP_NAME, APP_NAME_US, MATH_SUB_STATUS, RENEWAL_STATUS } from '../../../../services/constants';
import moment from 'moment';
import styles from './GoReadDashboard.module.scss';
import cx from 'classnames';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import * as Icon from 'react-feather';
import { useTranslation, Trans } from 'react-i18next';
import SideModal from '../../../../components/SideModal';
import ButtonLink from '../../../../components/ButtonLink';
import SaveConfirm from '../../../../components/SaveConfirm';
import ActiveTrialSubscription from '../../components/ActiveTrialSubscription';
import PriceQuote from '../../components/PriceQuote';
import PurchaseDataDashboardAccess from '../../components/PurchaseDataDashboardAccess';
import LikesSeenAccess from '../../components/LikesSeenAccess';
import DataDownloadAccess from '../../components/DataDownloadAccess';
import PupilNotesAccess from '../../components/PupilNotesAccess';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import AutoRenewal from '../../components/AutoRenewal';

const PERMISSIONS = {
    dataDownload: 1,
    pupilNotes: 2,
    likesSeen: 3,
}

const GoReadDashboard = ({ subscription, subExpired }) => {
    const { t } = useTranslation(['apps']);
    const token = ls(getAppTokenName(APP_NAME));
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const schoolId = currentUser.school.id;
    const client = useApolloClient();
    const [uploading, setUploading] = useState(false);
    const [uploadSuccess, setUploadSuccess] = useState(false);
    const [formErrors, setFormErrors] = useState(null);
    const [showPricing, setShowPricing] = useState(false);

    const [showCancel, setShowCancel] = useState(false)
    const [enableCancel, setEnableCancel] = useState(false)
    const [cancelErrors, setCancelErrors] = useState([]);

    const [editPermission, setEditPermission] = useState(null);
    const [permissionSaved, setPermissionSaved] = useState(null);

    const [showGroupLogs, setShowGroupLogs] = useState(false);
    const [parentsAllowed, setParentsAllowed] = useState(false);
    const [parentsSaved, setParentsSaved] = useState(false);
    const now = moment()
    const daysTillSubExpires = moment(subscription.expiresAt.substring(0, 10)).diff(now, 'days');
    const daysSinceSubExpires = now.diff(moment(subscription.expiresAt.substring(0, 10)), 'days');
    const dateUntilAccessEnds = now.diff(moment(subscription.expiresAt.substring(0, 10)).add(14, 'days'), 'days');
    const renewal = (subscription.renewal?.status === RENEWAL_STATUS.new && subscription.renewal) || (subscription.secondYearRenewal?.status === RENEWAL_STATUS.new && subscription.secondYearRenewal);


    const { data, loading } = useQuery(GET_SCHOOL_QUERY, {
        variables: {
            schoolId: schoolId
        },
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            setParentsAllowed(data.school.allowParentsGroupLogs)
        }
    });

    const [saveUrl] = useMutation(SAVE_GUIDE_URL, {
        update: (_, { data }) => {
            if (data.addSchoolGuide.errors && data.addSchoolGuide.errors.length) {
                /*setFormErrors(data.addSchoolGuide.errors);*/
            } else {
                setUploading(false)
                setUploadSuccess(true);
                setTimeout(function () {
                    setUploadSuccess(false);
                }, 3500);
            }
        },
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_SCHOOL_QUERY, variables: { schoolId: schoolId } }]
    })

    const [endReadAccess, { loading: cancelling }] = useMutation(END_READ_ACCESS, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GET_READ_SUBSCRIPTIONS, variables: { schoolId }, fetchPolicy: 'network-only' }],
        variables: { schoolId },
        update: (_, { data }) => {
            if ((data && data.cancelBoomReadAccess.errors && data.cancelBoomReadAccess.errors.length > 0)) {
                setCancelErrors(data.cancelBoomReadAccess.errors);
                return;
            }
            if (data?.cancelBoomReadAccess?.school?.id) {
                const cu = { ...currentUser, school: data.cancelBoomReadAccess.school }
                setCurrentUser(cu);
                setShowCancel(false);
                setEnableCancel(false);
            }
        },
    })


    useEffect(() => {
        setParentsSaved(false)
    }, [parentsAllowed])

    const [setParentLogs, { loading: setParentsLogLoading }] = useMutation(SET_PARENTS_GROUP_LOGS, {
        update: (_, { data }) => {
            if (data.editSchoolSettingsForParentsGroupLogs.errors && data.editSchoolSettingsForParentsGroupLogs.errors.length) {
                /*setFormErrors(data.addSchoolGuide.errors);*/
            } else {
                setParentsSaved(true)
                setShowGroupLogs(false)
            }
        },
        awaitRefetchQueries: true,
    })

    const onSelectFile = (files) => {
        if (files.length) {
            const extension = files[0].name.split('.').pop().toLowerCase();
            const filename = `guides/${schoolId}-guide-${new Date().getTime()}.${extension}`;
            if (extension !== 'pdf') {
                setFormErrors('Please select a pdf.');
                return null;
            }
            if (files.length > 1) {
                setFormErrors('Please only select one file to upload.');
                return null;
            }
            if (files[0].size > 10240000) {
                setFormErrors('PDFs must be smaller than 10mb.');
                return null;
            }
            setUploading(true);
            setFormErrors(null);
            client.query({
                query: GET_S3_UPLOAD_URL_QUERY,
                variables: {
                    name: filename
                }
            }).then(r => {
                const options = { headers: { 'Content-Type': '', 'x-amz-acl': 'public-read' } }
                //console.log('tying amazon upload')
                axios.put(r.data.s3UploadUrl, files[0], options).then(s3r => {
                    //console.log(s3r) 
                    saveUrl({
                        variables: {
                            schoolId: schoolId,
                            guideUrl: filename,
                        }
                    })
                })
            })
        }

    }

    if (showPricing) {
        return <PriceQuote close={() => setShowPricing(false)} />
    }

    if (showCancel) {
        return (
            <div className={styles.cancel}>
                <div className={styles.cancelHeader}>
                    <h3>Cancel your subscription</h3>
                    <button className='btn-reset' onClick={() => { setShowCancel(false); setEnableCancel(false) }}><Icon.XCircle /></button>
                </div>
                <p className='u-m-base-2'>{daysTillSubExpires} days remaining</p>
                {subscription.isStripe &&
                    <p className='u-m-base-2'>Unless you cancel, your card will be charged £{subscription.payments.length > 0 && subscription.payments[0].amount.toFixed(2)} on {moment(subscription.nextPaymentDate, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</p>
                }
                <h3>Are you sure?</h3>
                <div className={styles.cancelPanel}>
                    <div className="basic-form__group basic-form__group--check u-m-base-2 u-m-top-2">
                        <input
                            id='startCancel'
                            type='checkbox'
                            value={enableCancel}
                            checked={enableCancel}
                            className='basic-form__check-box'
                            onChange={(e) => setEnableCancel(e.target.checked)}
                        />
                        <label htmlFor='startCancel' className="basic-form__check-label">Yes, please cancel my subscription</label>
                    </div>
                    {cancelErrors && <FormErrors errors={cancelErrors} />}
                    <Button disabled={!enableCancel || cancelling} onClick={() => endReadAccess()}>{cancelling ? 'Saving...' : 'Cancel subscription'}</Button>
                </div>
            </div>
        )
    }

    return (
        <div>
            {subscription.invoiceRequest && !renewal &&
                <div className={cx('card card-mobile', styles.goReadDashHeader)}>
                    <p className={styles.goReadDashHeaderCount}>{data?.school?.studentsUsingGoRead}/{subscription.secondYearSubscribedSeats !== null ? subscription.secondYearSubscribedSeats : subscription.invoiceRequest.studentsCounter} Pupils</p>
                </div>
            }
            <div className={styles.goReadDashContent}>
                <div className={styles.goReadDashLeft}>
                    {subExpired ?
                        subscription.isTrial ?
                            <>
                                {currentUser.school.canAccessGoReadApp ?
                                    <div className={styles.trialEnd}>
                                        <h2>Trial Expired</h2>
                                        <h3>Go Read access ends in {dateUntilAccessEnds.toString().substring(1)} days</h3>
                                        <p className="u-m-base-2">Please purchase a subscription before {moment(subscription.expiresAt.substring(0, 10)).add(14, 'days').format('DD MMMM')} to continue access to BoomReader.</p>
                                        <div className={styles.subFlex}>
                                            <ButtonLink to='/settings/boomreader/purchase'>Renew Now</ButtonLink>
                                        </div>
                                    </div>
                                    :
                                    <div className={cx(styles.trialEnd, styles.trialBlock)}>
                                        <h2>Trial Expired</h2>
                                        <p className="u-m-base-2">Please purchase a subscription to continue using BoomReader.</p>
                                        <div className={styles.subFlex}>
                                            <ButtonLink to='/settings/boomreader/purchase'>Purchase Now</ButtonLink>
                                        </div>
                                    </div>
                                }
                            </>
                            :
                            <>
                                {daysSinceSubExpires >= 14 &&
                                    <div className={styles.subPanel}>
                                        <h2 className="u-bold">Subscription Expired.</h2>
                                        <p className="u-m-base-2">Renew now to keep your existing renewal date with no loss of access.</p>
                                        <ButtonLink to='/settings/boomreader/purchase'>Purchase Subscription</ButtonLink>
                                    </div>
                                }
                                {(daysSinceSubExpires >= 0 && daysSinceSubExpires < 14) &&
                                    <div className={styles.subPanel}>
                                        <h2 className="u-bold">Your subscription has expired.<br />
                                            {dateUntilAccessEnds === 0 ? 'Renew now to keep access' : `Renew within ${dateUntilAccessEnds.toString().substring(1)} ${dateUntilAccessEnds == '-1' ? 'day' : 'days'} to keep access`}
                                        </h2>
                                        <p className="u-m-base-2">Renew now to keep your existing renewal date with no loss of access.</p>
                                        <div className={styles.subPanelAction}>
                                            <div className={styles.subPanelBtn}>
                                                <ButtonLink to='/settings/boomreader/purchase' state={{ renew: true }}>Renew Now</ButtonLink>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        :
                        <>
                            {subscription.isTrial ?
                                <ActiveTrialSubscription setShowPricing={setShowPricing} subscription={subscription} />
                                :
                                <>
                                    {renewal && renewal.status === RENEWAL_STATUS.new ?
                                        <AutoRenewal setShowCancel={setShowCancel} renewal={renewal} subscription={subscription} setCurrentUser={setCurrentUser} user={currentUser} /> :
                                        <>
                                            {(daysSinceSubExpires >= -14 && daysSinceSubExpires < 0) ?
                                                <div className={styles.subPanel}>
                                                    <h2 className="u-bold">Your trial ends in {daysSinceSubExpires.toString().substring(1)} days</h2>
                                                    <p className="u-m-base-2">Renew now to keep your existing renewal date with no loss of access.</p>
                                                    <div className={styles.subPanelAction}>
                                                        <div className={styles.subPanelBtn}>
                                                            <ButtonLink to='/settings/boomreader/purchase' state={{ renew: true }}>Renew Now</ButtonLink>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <a className={styles.appLink} href={`${getUrl(APPS.go_read_teacher)}?token=${token}&schoolId=${currentUser.school.id}`}>
                                                    <ImageFadeIn src={boomreaderImg} width="70px" className={styles.appLinkImg} />
                                                    <div className={styles.appLinkSide}>
                                                        <Trans i18nKey='apps:launch_goread'>
                                                            Launch <span>BoomReader</span>
                                                        </Trans>
                                                    </div>
                                                </a>
                                            }
                                        </>}
                                </>
                            }
                        </>
                    }

                    {!renewal &&
                        <div className={cx('card card-mobile u-m-top-2')}>
                            {(subscription.status === MATH_SUB_STATUS.cancelled || subscription.status === MATH_SUB_STATUS.cancelled_renewal) ?
                                <>
                                    <h3>Your Subscription:</h3>
                                    <p>{t('purchased')}: {moment(subscription.createdAt, 'YYYY-MM-DD').format('DD MMMM, YYYY')}</p>
                                    <ul className={cx(styles.subDates, styles.cancelledLabel)}>
                                        <li>
                                            <span className={styles.subDatesLabel}>Cancelled: </span>
                                            <span>{moment(subscription.subscriptionCancelledAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}</span>
                                        </li>
                                    </ul>
                                    {daysTillSubExpires > 0 ?
                                        <p>You can continue to access BoomReader until: {moment(subscription.expiresAt, 'YYYY-MM-DD').format('MMMM DD, YYYY')}.</p> :
                                        <p>Your subscription has been cancelled</p>
                                    }
                                    {/* TODO: For cancelled_renewal, no scenarion to restart yet */}
                                    {subscription.status === MATH_SUB_STATUS.cancelled &&
                                        <ButtonLink to='/settings/boomreader/purchase'>Renew Now</ButtonLink>
                                    }
                                </>
                                :
                                <>
                                    {!subscription.isTrial && subscription.invoiceRequest && !subExpired && (
                                        <>
                                            <h3>Your Subscription: {data?.school?.studentsUsingGoRead}/{subscription.secondYearSubscribedSeats !== null ? subscription.secondYearSubscribedSeats : subscription.invoiceRequest.studentsCounter} Pupils</h3>
                                            <p>{t('purchased')}: {moment(subscription.invoiceRequest.orderedAt, 'YYYY-MM-DD').format('DD MMMM, YYYY')}</p>
                                            <p>{t('expires')}: {moment(subscription.expiresAt, 'YYYY-MM-DD').format('DD MMMM, YYYY')}</p>

                                            <h3 className='u-m-top-2 heavy'>Additional Features:</h3>
                                            <PurchaseDataDashboardAccess setCurrentUser={setCurrentUser} currentUser={currentUser} subscription={subscription} />

                                            <div className={styles.dashPan}>
                                                <span className={cx(styles.goReadDashTextLink, 'u-m-top-2')} onClick={() => setShowCancel(true)}><ChevronsRight /><span className='u-m-left-1'>Cancel Subscription</span></span>
                                            </div>
                                        </>
                                    )}
                                    {subscription && subscription.isStripe && !subExpired &&
                                        <div className={styles.subPanel}>
                                            <h3>Your Subscription: {data?.school?.studentsUsingGoRead}/{subscription.subscribedSeats} Pupils</h3>
                                            <p>{t('purchased')}: {moment(subscription.createdAt, 'YYYY-MM-DD').format('DD MMMM, YYYY')}</p>
                                            {subscription.status === MATH_SUB_STATUS.cancelled_renewal ?

                                                <>
                                                </> :
                                                <>
                                                    <p>Renews: {moment(subscription.nextPaymentDate, 'YYYY-MM-DD').format('DD MMMM, YYYY')}</p>
                                                    <div className={styles.dashPan}>
                                                        <span onClick={() => setShowCancel(true)} className={cx(styles.goReadDashTextLink, 'u-m-top-2')}><ChevronsRight /><span className='u-m-left-1'>Cancel Subscription</span></span>
                                                    </div>

                                                </>
                                            }
                                        </div>
                                    }
                                </>
                            }
                            {subscription && subscription.isStripe &&
                                <>
                                    <h3>Billing History</h3>
                                    <ul>
                                        {
                                            subscription.payments.map(p => {
                                                return (<li key={p.id} className={styles.billingItem}><span>{moment(p.createdAt, 'YYYY-MM-DD').format('DD MMMM, YYYY')} - £{p.amount.toFixed(2)}</span><a href={p.receiptUrl}>Download Receipt</a></li>)
                                            })
                                        }
                                    </ul>
                                </>
                            }
                        </div>
                    }
                </div>
                <div className={styles.goReadDashRight}>
                    <div className={cx(styles.uploadPanel, 'card card-mobile')}>
                        {currentUser.school.canAccessGoReadApp ?
                            <div className={cx(styles.dashPan, "u-m-base-2")}>
                                <h3 className={styles.settingsHeader}><Icon.Settings className="u-m-right-1" />Settings</h3>
                                {loading && <p></p>}
                                {data &&
                                    <>
                                        {data.school.guideUrl && <a href={data.school.guideUrl} className='u-text-tertiary link-underline' target='blank' rel="noopener noreferrer">{t('view_current_reading_guide')}</a>}
                                        <div className='u-m-top-2'>
                                            {uploading ? <span className={cx(styles.uploadLabel, styles.uploadLabelLoading)}>{t('uploading')}&hellip;</span>
                                                :
                                                <>
                                                    <label htmlFor='GuideUpload' className={cx(styles.uploadLabel, 'u-m-right-2')}><Icon.PlusCircle className={styles.uploadIcon} />{data.school.guideUrl ? t('upload_new_guide') : t('upload_guide')}</label>
                                                    <input id='GuideUpload' type='file' onChange={e => onSelectFile(e.target.files)} className={cx('u-m-base-2', styles.uploadForm)} accept='application/pdf' />
                                                </>
                                            }
                                            {uploadSuccess && <div className={styles.successPanel}><Icon.CheckCircle className={styles.uploadIcon} />{t('successfully_uploaded')}</div>}
                                        </div>
                                        {formErrors && <p className={styles.uploadErrors}>{t('oops_something_went_wrong')} {t(formErrors)}</p>}
                                    </>
                                }

                                
                                <h3 onClick={() => window.location.href = `${getUrl(APPS.go_read_teacher)}${ls('go_impersonating') ? '/banding/all/impersonate' : '/banding/all/'
                                    }?token=${token}&schoolId=${currentUser.school.id}`} className={cx(styles.settingsHeader, styles.downloadData)}>Reading Bands<Icon.Edit3 /></h3>

                                <h3 onClick={() => setEditPermission(PERMISSIONS.dataDownload)} className={cx(styles.settingsHeader, styles.downloadData)}>Data download <Icon.Edit3 /></h3>
                                {permissionSaved === PERMISSIONS.dataDownload && <SaveConfirm hideConfirm={() => setPermissionSaved(false)} />}

                                <h3 onClick={() => setEditPermission(PERMISSIONS.pupilNotes)} className={cx(styles.settingsHeader, styles.downloadData)}>Pupil Notes <Icon.Edit3 /></h3>
                                {permissionSaved === PERMISSIONS.pupilNotes && <SaveConfirm hideConfirm={() => setPermissionSaved(false)} />}

                                <h3 onClick={() => setShowGroupLogs(true)} className={cx(styles.settingsHeader, styles.downloadData)}>Group Log Settings<Icon.Edit3 /></h3>
                                {parentsSaved && <SaveConfirm hideConfirm={setParentsSaved} />}

                                <h3 onClick={() => setEditPermission(PERMISSIONS.likesSeen)} className={cx(styles.settingsHeader, styles.downloadData)}>Seen by &amp; Likes<Icon.Edit3 /></h3>
                                {permissionSaved === PERMISSIONS.likesSeen && <SaveConfirm hideConfirm={() => setPermissionSaved(false)} />}
                            </div>
                            :
                            <div className={styles.sideLock}>
                                <Lock size='44' />
                                <p style={{ margiTop: '1rem', fontWeight: 'bold' }}>Access Locked</p>
                            </div>
                        }
                        <div className='u-display-flex'>
                            <Icon.LifeBuoy />
                            <div className='u-m-left-2'>
                                <p className='u-m-base-0'>Subscriptions &amp; Accounts:</p>
                                <p className='u-m-base-2'><a className='link-underline u-text-dark' href='mailto:accounts@squirrellearning.com'>accounts@squirrellearning.com</a></p>
                                <p className='u-m-base-0'>General Help &amp; Support:</p>
                                <a className='link-underline u-text-dark' href='mailto:support@boomhub.app'>support@boomhub.app</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            {editPermission === PERMISSIONS.dataDownload && (
                <SideModal closeModal={() => setEditPermission(null)}>
                    <div className="row">
                        <div className="col-lg-8">
                            <DataDownloadAccess school={currentUser.school} setShowSaveConfirm={() => setPermissionSaved(PERMISSIONS.dataDownload)} closeModal={() => setEditPermission(null)} />
                        </div>
                    </div>
                </SideModal>
            )}

            {editPermission === PERMISSIONS.likesSeen && (
                <SideModal closeModal={() => setEditPermission(null)}>
                    <div className="row">
                        <div className="col-lg-8">
                            <LikesSeenAccess school={currentUser.school} setShowSaveConfirm={() => setPermissionSaved(PERMISSIONS.likesSeen)} closeModal={() => setEditPermission(null)} />
                        </div>
                    </div>
                </SideModal>
            )}

            {showGroupLogs && (
                <SideModal closeModal={() => setShowGroupLogs(false)}>
                    <div className="row">
                        <div className="col-lg-8">
                            <h2 className='heavy'>Group Log Settings</h2>
                            <p className='heavy u-m-base-2'>This setting allows parents to add individual logs for their children to group books they are a part of. These are only seen in the child's individual account.</p>
                            <ul className={cx(styles.roleCheckList, 'u-m-base-3')}>
                                <li className={styles.striped}>
                                    <p>Allow parents to add reading logs to Group Books.</p>

                                    <div className="basic-form__group basic-form__group--check">
                                        <input
                                            type="checkbox"
                                            onChange={() => setParentsAllowed(!parentsAllowed)}
                                            className="switchToggle"
                                            name='role'
                                            value={parentsAllowed}
                                            checked={parentsAllowed}
                                            id={`allowParents`}
                                        />
                                        <label className='switchLabel' htmlFor={`allowParents`}>toggle</label>
                                    </div>
                                </li>
                            </ul>
                            <div>
                                <Button onClick={() => setParentLogs({
                                    variables: {
                                        schoolId,
                                        allowParentsGroupLogs: parentsAllowed
                                    }
                                })} disabled={setParentsLogLoading}>{setParentsLogLoading ? t('common:saving') : t('common:button.save')}</Button>
                            </div>
                        </div>
                    </div>
                </SideModal>
            )}

            {editPermission === PERMISSIONS.pupilNotes && (
                <SideModal closeModal={() => setEditPermission(null)}>
                    <div className="row">
                        <div className="col-lg-8">
                            <PupilNotesAccess school={currentUser.school} setShowSaveConfirm={() => setPermissionSaved(PERMISSIONS.pupilNotes)} closeModal={() => setEditPermission(null)} />
                        </div>
                    </div>
                </SideModal>
            )}

        </div>
    )
}

export default GoReadDashboard;