import React, { useContext, useState, useEffect } from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import ls from 'local-storage';
import { navigate } from '@reach/router';
import { Loader, FormErrors } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import { TEACHER_CONFIRM_EMAIL } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { APP_NAME, APP_NAME_US } from '../../../services/constants';

const ConfirmEmail = ({ token }) => {

    const { setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();
    const [formErrors, setFormErrors] = useState([]);

    const [confirmNewEmail] = useMutation(TEACHER_CONFIRM_EMAIL, {
        onCompleted: data => {
            if (data.teacherConfirmEmail.errors && data.teacherConfirmEmail.errors.length !== 0) {
                setFormErrors(data.teacherConfirmEmail.errors);
                return
            }
            if (data.teacherConfirmEmail.token) {
                ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME), data.teacherConfirmEmail.token);
                ls('go_teacher_user', JSON.stringify(data.teacherConfirmEmail.user));
                data.teacherConfirmEmail.user?.school?.id && ls('schoolId', data.teacherConfirmEmail.user.schoo.id);
                setCurrentUser({
                    ...data.teacherConfirmEmail.user,
                    reset: true
                });
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
        }
    });
    useEffect(() => {
        confirmNewEmail({
            variables: {
                token
            }
        })
    }, []);

    return (
        <div className='auth'>
            <div className="auth__content auth__login-wrapper">
                <div className='auth__panel auth__panel-main'>
                    <h1 className='auth-page__title'>Confirming Your Email!</h1>
                    <div style={{
                        width: '200px',
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '40px auto'
                    }}>
                        <Loader />
                    </div>
                    {formErrors.length > 0 && <FormErrors errors={formErrors} />}
                </div>
            </div>
        </div>
    )
}

export default ConfirmEmail;