import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';

import { Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import ForgotPassword from './scenes/ForgotPassword';
import { AuthContext } from '../../services/auth/AuthProvider';
import { LOGIN } from './services/graphql';
import { APP_NAME } from '../../services/constants';
import LoginForm from './components/LoginForm';
import AuthHeader from './components/AuthHeader';
import { useTranslation } from 'react-i18next';
import AuthLinks from './components/AuthLinks';
import CountrySelect from './components/CountrySelect'

const BetaWondeLogin = () => {

    const { t } = useTranslation();

    const [formErrors, setFormErrors] = useState([]);
    const [modalForgotPass, setModalForgotPass] = useState(false);
    const [showCountrySelect, setShowCountrySelect] = useState(false)

    const client = useApolloClient();
    const auth = useContext(AuthContext);

    const [login, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.teacherLogIn.errors && data.teacherLogIn.errors.length !== 0) {
                setFormErrors(data.teacherLogIn.errors);
                return;
            }
            if (data.teacherLogIn.teacher && data.teacherLogIn.teacher.id && data.teacherLogIn.token) {
                ls(getAppTokenName(APP_NAME), data.teacherLogIn.token);
                ls('go_teacher_user', JSON.stringify(data.teacherLogIn.teacher));
                data.teacherLogIn.teacher?.school?.id && ls('schoolId', data.teacherLogIn.teacher.school.id);
                auth.setCurrentUser(data.teacherLogIn.teacher);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    return (
        <div className='auth'>
            <AuthHeader setShowCountrySelect={setShowCountrySelect} />
            <div className="auth__content auth__login-wrapper">
                <div className='auth__panel auth__panel-main'>
                    {showCountrySelect ?
                        <>
                            <CountrySelect setShowCountrySelect={setShowCountrySelect} />
                        </>
                        :
                        <>
                            <AuthLinks country={ls('country') === 'US' ? 'US' : 'GB'} />
                            <h1 className='u-m-base-2 heavy h2'>{t('teacher_login')}</h1>
                            <div className="auth__form">
                                <LoginForm showWondeBtn={true} formErrors={formErrors} loggingIn={loggingIn} loginMethod={login} setModalForgotPass={setModalForgotPass} setShowCountrySelect={setShowCountrySelect} />
                            </div>
                        </>
                    }
                </div>
                {
                    modalForgotPass && (
                        <Modal closeModal={() => setModalForgotPass(false)}>
                            <ForgotPassword closeModal={() => setModalForgotPass(false)} />
                        </Modal>
                    )
                }
            </div>

        </ div>

    )
}

export default BetaWondeLogin;
