import React, { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import { useClickAway, useWindowSize } from 'react-use';
import { ChevronDown } from 'react-feather';

const SideClassNav = ({ tabs, selectedTab, setSelectedTab, baseTeachers, removedTeachers, joinRequests }) => {

    const { t } = useTranslation(['classes', 'common']);

    const [dropdown, setDropdown] = useState(false);

    const ref = useRef(null);
    useClickAway(ref, () => setDropdown(false));

    const { width } = useWindowSize();

    useEffect(() => {
        setDropdown(false);
    }, [selectedTab]);

    const getSelectedTabTitle = selectedTab => {
        switch (selectedTab) {
            case tabs.allActiveTeachers:
                return t('all_active_staff');
            case tabs.administrators:
                return t('administrators');
            case tabs.other:
                return 'Other Staff';
            case tabs.removedTeachers:
                return t('removed');
            case tabs.requests:
                return "Requests";
            default:
                return t('common:teachers');
        }
    }

    const renderLinks = (styleName, activeStyleName) => {
        return (
            <>
                <li>
                    <button onClick={() => setSelectedTab(tabs.allActiveTeachers)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.allActiveTeachers })}>
                        <div>{t('all_active_staff')}</div>
                        <span>{baseTeachers.length}</span>
                    </button>
                </li>
                <li>
                    <button onClick={() => setSelectedTab(tabs.administrators)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.administrators })}>
                        <div>{t('administrators')}</div>
                        <span>{baseTeachers.filter(t => t.isSchoolAdmin).length}</span>
                    </button>
                </li>
                <li>
                    <button onClick={() => setSelectedTab(tabs.other)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.other })}>
                        <div>Other Staff</div>
                        <span>{baseTeachers.filter(t => !t.isSchoolAdmin).length}</span>
                    </button>
                </li>
                <li>
                    <button onClick={() => setSelectedTab(tabs.removedTeachers)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.removedTeachers })}>
                        <div>{t('removed')}</div>
                        <span>{removedTeachers.length}</span>
                    </button>
                </li>

                <li>
                    <button onClick={() => setSelectedTab(tabs.requests)} className={cx(styleName, { [activeStyleName]: selectedTab === tabs.requests })}>
                        <div>Requests</div>
                        <span>{joinRequests?.length}</span>
                    </button>
                </li>

            </>
        )
    }

    return (
        <>
            {width < 768 ?
                <div ref={ref} className='dropdownHolder u-m-base-3'>
                    <button className='dropdownBtn dropdownBtnFull' onClick={() => setDropdown(!dropdown)}>{getSelectedTabTitle(selectedTab)} <ChevronDown /> </button>
                    {dropdown && (
                        <ul className='dropdownMenu dropdownMenuFull'>
                            {renderLinks('dropdownMenuItem', 'dropdownMenuItemActive')}
                        </ul>
                    )}
                </div> :
                <ul className='side-nav-list'>
                    {renderLinks('side-nav-btn', 'side-nav-btn-active')}
                </ul>
            }
        </>
    );
}

export default SideClassNav;
