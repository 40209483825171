import React, { useContext, useState } from "react";
import { AuthContext } from "../../services/auth/AuthProvider";
import ls from 'local-storage';
import { navigate } from "@reach/router";
import { useLazyQuery, useApolloClient } from "@apollo/react-hooks";
import { CURRENT_TEACHER_QUERY } from "../Home/services/graphql";
import schoolDefault from '../../images/school-default.svg';
import ImageFadeIn from "react-image-fade-in";
import styles from './SchoolSelector.module.scss';
import { CheckCircle } from "react-feather";
import PageHeader from "../../components/PageHeader";

const SchoolSelector = ({ fromDashboard = false, onSelect = null }) => {

    const { currentUser, setCurrentUser } = useContext(AuthContext) || {};
    const [uiSelect, setUiSelect] = useState(null);

    const client = useApolloClient();

    const [getUser] = useLazyQuery(CURRENT_TEACHER_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ currentTeacher }) => {
            if (currentTeacher?.school?.id) {
                client.clearStore();
                setCurrentUser(currentTeacher);
                if (fromDashboard) {
                    ls('selectedSchool', true);
                    onSelect && onSelect();
                } else {
                    Promise.resolve().then(() => {
                        navigate('/');
                    });
                }
            }
        }
    });

    const onSelectSchool = school => {
        ls('schoolId', school.id);
        setUiSelect(school.id);
        getUser();
    }


    return (
        <>
            {fromDashboard ?
                <PageHeader title={'Choose school:'} showBackLink={false} /> :
                <PageHeader title={'Switch school'} />
            }
            <div className="row">
                {currentUser.schools.map(school =>
                    <div key={school.id} className="col-md-4">
                        <div onClick={() => onSelectSchool(school)} className={`card ${styles.school} ${school.id === (fromDashboard ? uiSelect : currentUser?.school?.id) ? styles.active : 'card'}`}>
                            <div className="card-body">
                                <ImageFadeIn className={styles.logo} src={school?.avatarUrl || schoolDefault} />
                            </div>
                            <div className="card-footer bg-white">
                                <p className="u-m-base-2 u-display-flex">
                                    {school.id === (fromDashboard ? uiSelect : currentUser?.school?.id) && (
                                        <CheckCircle className="u-m-right-1" />
                                    )}
                                    {school.name}
                                </p>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        </>
    )
}

export default SchoolSelector