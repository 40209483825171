
import React, { useState, useContext } from 'react';
import ImageFadeIn from "react-image-fade-in";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient, useQuery } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ls from 'local-storage';
import { navigate } from '@reach/router';
import { TEACHER_JOB_ROLES, TERMS_VERSION } from '@axeedge/go-shared-utils';
import { Lock } from 'react-feather';
import { getRoleLabel, usaOnlyRoles, ukOnlyRoles } from '../../../../services/utils';

import { Button, Modal, FormErrors, Loader } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';
import { useTranslation } from 'react-i18next';

import { TEACHER_INVITE_ACCEPT, TEACHER_INVITE_DECLINE, TEACHER_BY_TOKEN } from '../../services/graphql';
import logo from '../../../../images/boomer.svg';
import hourGlass from '../../../../images/hourglass.png';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import { APP_NAME } from '../../../../services/constants';
import styles from './AcceptInvite.module.scss';
import CountrySelect from '../../components/CountrySelect'
import cx from 'classnames';


const EXPIRED_TOKEN_CHECK = 'expired_token@goapps.com';

const AcceptInvite = ({ token }) => {

    const { t } = useTranslation(['profile', 'common']);

    const [inviteDeclined, setInviteDeclined] = useState(false);
    const [confirmDecline, setConfirmDecline] = useState(false);

    const { setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();
    const [formErrors, setFormErrors] = useState([]);
    const [declineErrors, setDeclineErrors] = useState([]);
    const [showCountrySelect, setShowCountrySelect] = useState(false)

    const { data, loading, error } = useQuery(TEACHER_BY_TOKEN, {
        fetchPolicy: 'nework-only',
        variables: {
            token
        }
    })

    const [teacherInviteAccept, { loading: acceptingInvite }] = useMutation(TEACHER_INVITE_ACCEPT, {
        onCompleted: data => {
            if (data.teacherInviteAccept.token) {
                ls(getAppTokenName(APP_NAME), data.teacherInviteAccept.token);
                ls('go_teacher_user', JSON.stringify(data.teacherInviteAccept.teacher));
                data.teacherInviteAccept.teacher?.school?.id && ls('schoolId', data.teacherInviteAccept.teacher?.school.id);
                setCurrentUser({
                    ...data.teacherInviteAccept.teacher,
                    reset: true
                });
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
            if (data.teacherInviteAccept.errors && data.teacherInviteAccept.errors.length !== 0) {
                setFormErrors(data.teacherInviteAccept.errors);
            }
        }
    });

    const [teacherInviteDecline, { loading: decliningInvite }] = useMutation(TEACHER_INVITE_DECLINE, {
        onCompleted: data => {
            if (data.teacherInviteDecline.deleted) {
                setConfirmDecline(false);
                setInviteDeclined(true);
            }
            if (data.teacherInviteDecline.errors && data.teacherInviteDecline.errors.length !== 0) {
                setDeclineErrors(data.teacherInviteDecline.errors)
            }
        }
    });


    const yupTest = () => {
        let pass = false;
        if (Yup) {
            if (Yup.object) {
                if (Yup.object().shape) {
                    if (Yup.string) {
                        if (Yup.string().required) {
                            if (Yup.string().oneOf) {
                                if (Yup.ref) {
                                    pass = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return pass;
    }

    const schema = yupTest() ? Yup.object().shape({
        firstName: Yup.string().required(t('common:first_name_validation')),
        lastName: Yup.string().required(t('common:last_name_validation')),
        displayName: Yup.string().required(t('common:display_name_validation')),
        email: Yup.string().email().required(t('enter_your_email')),
        tos: Yup.boolean().oneOf([true], t('must_agree_to_tos')),
        roleId: Yup.string().required(t('common:please_select_role')),
        password: Yup.string()
            .required(t('please_enter_password')),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('passwords_must_match'))
    }) : null;

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onSubmit"
    });

    const onSubmit = values => {
        const { firstName, lastName, displayName, email, roleId, password, passwordConfirmation, schoolId } = values;
        teacherInviteAccept({
            variables: {
                firstName,
                lastName,
                displayName,
                email,
                roleId: +roleId,
                password,
                passwordConfirmation,
                version: (TERMS_VERSION).toString(),
                schoolId,
                resetToken: token
            }
        });
    };

    if (loading) {
        return <Loader />
    }

    if (error) {
        return <p>{error.message}</p>
    }

    if (inviteDeclined) {
        return (
            <div className='auth'>
                <div className='auth__header'>
                    <div className='auth__header__logo'>
                        <ImageFadeIn className='' src={logo} alt="Go Apps Logo" />
                        <h1 className='u-m-base-0 heavy'>BoomReader &amp; BoomWriter</h1>
                    </div>
                </div>
                <div className="auth__content">
                    <div className='auth__panel'>
                        <div className={styles.acceptInvite}>
                            <h3>Invitation declined successfully</h3>
                            <Button onClick={() => navigate('/')}>Done</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    if (data) {

        const { teacherByToken } = data;

        return (

            <div className='auth'>
                <div className='auth__header'>
                    <ImageFadeIn src={logo} alt="Go Apps Logo" className="auth__applogo" />
                </div>


                <div className="auth__content">
                    <div className={cx('auth__panel', { [styles.authPanelNarrow]: teacherByToken?.email === EXPIRED_TOKEN_CHECK })}>
                        {showCountrySelect ?
                            <>
                                <CountrySelect setShowCountrySelect={setShowCountrySelect} />
                            </> :
                            <div className={styles.acceptInvite}>
                                {teacherByToken ? (
                                    <>
                                        {teacherByToken.invitedSchools[0] ? (
                                            <>
                                                <h1>You've been invited to join</h1>
                                                <div className={styles.acceptInviteHeader}>
                                                    <div className={styles.acceptInviteHeaderImg}>
                                                        <ImageFadeIn src={teacherByToken.invitedSchools[0].avatarUrl} />
                                                    </div>
                                                    <div className={styles.acceptInviteHeaderMain}>
                                                        <h3 className='u-m-base-0'>{teacherByToken.invitedSchools[0].name}</h3>
                                                        <p>{teacherByToken.invitedSchools[0].city}, {teacherByToken.invitedSchools[0].country?.name}</p>
                                                    </div>
                                                    <input type="hidden" name='schoolId' ref={register} defaultValue={teacherByToken.invitedSchools[0].id} />
                                                </div>

                                                <div className={styles.acceptInviteForm}>
                                                    <div>
                                                        <h3>To accept, please complete the following:</h3>
                                                        <form onSubmit={handleSubmit(onSubmit)}>
                                                            <div className={styles.acceptInviteCol}>
                                                                <div className='basic-form__group'>
                                                                    <label htmlFor='firstName' className='basic-form__text-label'>{t('common:first_name')}</label>
                                                                    <input
                                                                        name='firstName'
                                                                        defaultValue={teacherByToken.firstName}
                                                                        placeholder={t('common:first_name_placeholder')}
                                                                        className='basic-form__text-box'
                                                                        ref={register}
                                                                        type='text'
                                                                    />
                                                                </div>
                                                                <div className='basic-form__group'>
                                                                    <label htmlFor='lastName' className='basic-form__text-label'>{t('common:last_name')}</label>
                                                                    <input
                                                                        name='lastName'
                                                                        defaultValue={teacherByToken.lastName}
                                                                        placeholder={t('common:last_name_placeholder')}
                                                                        className='basic-form__text-box'
                                                                        ref={register}
                                                                        type='text'
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                                                            {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}

                                                            <div className='basic-form__group'>
                                                                <label htmlFor='displayName' className='basic-form__text-label'>{t('common:display_name')}</label>
                                                                <input
                                                                    name='displayName'
                                                                    placeholder={t('common:display_name_placeholder')}
                                                                    className='basic-form__text-box'
                                                                    ref={register}
                                                                    type='text'
                                                                    defaultValue={teacherByToken.displayName}
                                                                />
                                                            </div>
                                                            {errors.displayName && <p className='basic-form__hint'>{errors.displayName.message}</p>}

                                                            <div className={styles.acceptInviteLocked}>
                                                                <p>{teacherByToken.email}</p><Lock />
                                                            </div>
                                                            <input type="hidden" name='email' ref={register} defaultValue={teacherByToken.email} />

                                                            {teacherByToken.roleId ? (
                                                                <>
                                                                    <div className={styles.acceptInviteLocked}>
                                                                        <p>{getRoleLabel(teacherByToken.roleId)}</p><Lock />
                                                                    </div>
                                                                    <input type="hidden" name='roleId' ref={register} defaultValue={teacherByToken.roleId} />
                                                                </>
                                                            ) : (
                                                                <div className="basic-form__group">
                                                                    <select name="roleId" ref={register} className="basic-form__text-select">
                                                                        <option value=''>Select your role</option>
                                                                        {ls('country') === 'US' ?
                                                                            Object.entries(TEACHER_JOB_ROLES).filter(r => !ukOnlyRoles.includes(r[1])).map(role => {
                                                                                return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                                                            })
                                                                            :
                                                                            Object.entries(TEACHER_JOB_ROLES).filter(r => !usaOnlyRoles.includes(r[1])).map(role => {
                                                                                return (<option key={`role-${role[1]}`} value={role[1]}>{getRoleLabel(role[1])}</option>)
                                                                            })}
                                                                    </select>
                                                                    {errors.roleId && <p className="basic-form__hint">{errors.roleId.message}</p>}
                                                                </div>
                                                            )}

                                                            <div className="basic-form__group">
                                                                <label htmlFor='password' className='basic-form__text-label'>Password</label>
                                                                <input
                                                                    name="password"
                                                                    className="basic-form__text-box"
                                                                    type="password"
                                                                    ref={register}
                                                                />
                                                            </div>
                                                            {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                                                            <div className="basic-form__group">
                                                                <label htmlFor='password' className='basic-form__text-label'>Confirm Password</label>
                                                                <input
                                                                    name="passwordConfirmation"
                                                                    className="basic-form__text-box"
                                                                    type="password"
                                                                    ref={register}
                                                                />
                                                            </div>
                                                            {errors.passwordConfirmation && <p className="basic-form__hint">{errors.passwordConfirmation.message}</p>}

                                                            <div className="basic-form__group basic-form__group--check">
                                                                <div>
                                                                    <input
                                                                        name="tos"
                                                                        className="basic-form__check-box"
                                                                        ref={register}
                                                                        type="checkbox"
                                                                        id="tos"
                                                                    />
                                                                    <label className="basic-form__check-label" htmlFor="tos">{t('i_agree_to_the')}</label>
                                                                </div>
                                                                <a target='blank' className='link-underline u-m-left-1 u-m-right-1 ' href='https://goapps.app/terms'>{t('terms')}</a> &amp;
                                                                <a target='blank' className='link-underline u-m-left-1' href='https://goapps.app/privacy'>{t('privacy_policy')}</a>
                                                            </div>

                                                            {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}

                                                            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}

                                                            <div className='u-display-flex u-justify-center u-m-top-3 u-m-base-3'>
                                                                <Button disabled={acceptingInvite} fullWidth type="submit">{acceptingInvite ? t('common:saving') : "Accept and join school"}</Button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                    <div className="side">
                                                        <p className='lead'>Decline options</p>
                                                        <button onClick={() => setConfirmDecline(true)} className='btn-reset link-underline'>Decline & delete account</button>
                                                        {declineErrors.length !== 0 && <FormErrors errors={declineErrors} />}
                                                    </div>
                                                </div>
                                            </>
                                        ) : teacherByToken.email === EXPIRED_TOKEN_CHECK ?
                                            <div className={styles.inviteExpired}>
                                                <ImageFadeIn width={180} src={hourGlass} alt='' />
                                                    <h2 className='heavy u-m-top-2'>Your invite has expired. </h2>
                                                <p>Please ask your school administator to resend you an invite.</p>
                                            </div> :
                                            <p>No school found for this invite</p>}
                                    </>
                                ) :
                                    <p>No invited user found</p>
                                }
                            </div>
                        }
                        {confirmDecline && (
                            <Modal closeModal={() => setConfirmDecline(false)}>
                                <div className="u-text-center">
                                    <h3 className='u-m-base-3'>Are you sure?</h3>
                                    <Button className='u-m-right-2' disabled={decliningInvite} onClick={() => teacherInviteDecline({
                                        variables: {
                                            teacherId: teacherByToken.id,
                                            schoolId: teacherByToken.invitedSchools[0].id
                                        }
                                    })}>{decliningInvite ? 'Saving...' : 'Yes'}</Button>
                                    <Button outline onClick={() => setConfirmDecline(false)}>No</Button>
                                </div>

                            </Modal>
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return null;

}

export default AcceptInvite;