import React from 'react';
import JobRoleForm from './JobRoleForm';
import styles from './JobRoleCheck.module.scss';

const JobRoleCheck = ({user}) => {

    return (
        <div className={styles.modal}>
            <div className={styles.modalBlanker}>
                <div className={styles.modalContent}>
                    <h3 className='heavy'>Please select your role in school: {user.school?.name}</h3>
                    <p className='u-m-base-2'>To assist in future updates and to help us streamline the app to fit your needs, we've introduced a new setting for you to identify your role in this school.</p>
                    <p className='u-m-base-2'>Please select the role/description that best fits your current position:</p>
                    <p className='heavy'>My role in school: <b>{user.school?.name}</b></p>
                    <JobRoleForm />
                </div>
            </div>
        </div>
    )
}

export default JobRoleCheck;