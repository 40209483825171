import React, { Fragment, useState, useContext } from 'react';
import ImageFadeIn from "react-image-fade-in";
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ls from 'local-storage';
import { navigate } from '@reach/router';
import { useTranslation, Trans } from 'react-i18next';

import { Button, Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import { UPDATE_PASSWORD_MUTATION } from '../services/graphql';
import ForgotPassword from './ForgotPassword';
import logo from '../../../images/boomer.svg';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { APP_NAME, APP_NAME_US } from '../../../services/constants';

const SetPassword = ({ token, '*': reset }) => {
    const { t } = useTranslation(['profile', 'common']);
    const [modalForgotPass, setModalForgotPass] = useState(false);
    const { setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();

    const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.updatePassword.token) {
                ls(getAppTokenName(ls('country') === 'US' ? APP_NAME_US : APP_NAME), data.updatePassword.token);
                ls('go_teacher_user', JSON.stringify(data.updatePassword.user));
                data.updatePassword.user?.school?.id && ls('schoolId', data.updatePassword.user.school.id);
                setCurrentUser({
                    ...data.updatePassword.user,
                    reset: true
                });
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
            if (data.updatePassword.errors && data.updatePassword.errors.length !== 0) {
                setModalForgotPass(data.updatePassword.errors);
            }
        }
    });
    
    const yupTest = () => {
        let pass = false;
        if (Yup) {
            // console.log('Yup', Yup && true);
            if (Yup.object) {
                // console.log('Yup.object', Yup.object && true);
                if (Yup.object().shape) {
                    // console.log('Yup.object().shape', Yup.object().shape && true);
                    if (Yup.string) {
                        // console.log('Yup.string', Yup.string && true);
                        if (Yup.string().required) {
                            // console.log('Yup.string().required', Yup.string().required && true);
                            if (Yup.string().oneOf) {
                                // console.log('Yup.string().oneOf', Yup.string().oneOf && true);
                                if (Yup.ref) {
                                    // console.log('Yup.ref', Yup.ref && true);
                                    pass = true;
                                }
                            }
                        }
                    }
                }
            }
        }
        return pass;
    }

    const schema = yupTest() ? Yup.object().shape({
        password: Yup.string()
            .required(t('please_enter_password')),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('passwords_must_match'))
    }) : null;

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { password, passwordConfirmation } = values;
        updatePassword({
            variables: {
                token,
                password,
                passwordConfirmation
            }
        })
    };

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <ImageFadeIn className='auth-page__logo' src={logo} />
                <h1 className='auth-page__title-light'>
                    {
                        !!reset ? (
                            <Fragment>
                                {t('reset_your_password')}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Trans i18nKey="profile:account_activation_set_password">
                                    Account activation: <br/> Set your password
                                </Trans>
                            </Fragment>
                        ) 
                    }
                </h1>
                <p className='u-m-base-2 u-text-center'>{!!reset ? t('to_reset_pass_and_login_complete_form_below') : t('to_complete_activation_set_pass_for_account')}:</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="basic-form__group">
                        <input
                            name="password"
                            placeholder={!!reset ? t('new_password') : t('password')}
                            className="basic-form__text-box"
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                    <div className="basic-form__group">
                        <input
                            name="passwordConfirmation"
                            placeholder={!!reset ? t('confirm_new_password') : t('confirm_password')}
                            className="basic-form__text-box"
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.passwordConfirmation && <p className="basic-form__hint">{errors.passwordConfirmation.message}</p>}
                    <div className='u-display-flex u-justify-center'>
                        <Button type="submit">{loading ? t('common:saving') : t('set_password_and_login')}</Button>
                    </div>
                </form>
            </div>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword invalid_token={modalForgotPass} closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default SetPassword;
