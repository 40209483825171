import { gql } from 'apollo-boost';

export const GET_SCHOOL_QUERY = gql`
query school($schoolId: ID!) {
    school(schoolId: $schoolId) {
        id
        guideUrl
        allowParentsGroupLogs
        studentsUsingGoRead
    }
}
`;

export const SAVE_GUIDE_URL = gql`
mutation addSchoolGuide($guideUrl: String!, $schoolId: ID!) {
    addSchoolGuide(guideUrl: $guideUrl, schoolId: $schoolId) {
        school {
            id
        }
        errors
    }
}
`;

export const SET_PARENTS_GROUP_LOGS = gql`
mutation editSchoolSettingsForParentsGroupLogs($schoolId: ID!, $allowParentsGroupLogs: Boolean!) {
    editSchoolSettingsForParentsGroupLogs(schoolId: $schoolId, allowParentsGroupLogs: $allowParentsGroupLogs) {
        school {
            id
            allowParentsGroupLogs
        }
        errors
    }
}
`